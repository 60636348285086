import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Index from './default/Index';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Details from './document/Details';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/document/:document_id/details" element={<Details />} />
        <Route path="/d/:document_id" element={<Details />} />
        <Route path="*" element={<Index />} />
      </Routes>
  </BrowserRouter>
  </React.StrictMode>
);