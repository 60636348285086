import React, { useState } from 'react';
import About from '../about/About';
import View from '../document/View';
import Description from '../document/Description';
import '../App.css'
import axios from 'axios';

function Index() {
  const [uploadedDocument, setUploadedCDocument] = useState(false);
  const [document, setDocument] = useState([]);
  const [endpoint, setEndpoint] = useState('https://api.axtern.com');

  function uploadFile(event) {
    event.preventDefault()
    axios.put(`${endpoint}/document/publish`, event.target.files[0]).then((response) => {
      setDocument(response.data)
      setUploadedCDocument(true)
    });
  }

  function handleEndpointChange(event) {
    try {
      new URL(event.target.value);
      setEndpoint(event.target.value)
    } catch (_) {
      // Bad URL - Don't Update
    }
  }

  function Upload() {
    return (
      <div>
        <h1>Upload Document</h1>
        <input type="file" onChange={uploadFile} id="uploadDocument" accept=".pdf,application/pdf" hidden />
        <label htmlFor="uploadDocument" id="uploadButton">Upload Document</label>
        <p><strong>Notice</strong>: All documents are publicly available.</p>
        <div className='formItem'>
                <div className='formLabel'>
                    <label htmlFor="documentId">Endpoint</label><br/>
                </div>
                <div className='formInput'>
                  <input id="endpoint" type="text" value={endpoint} onChange={handleEndpointChange} />
                </div>
            </div>
      </div>
    );
  }

  function LeftBox() {
    if (uploadedDocument) {
      return <View documentId={document['documentId']} endpoint={endpoint} />
    }
    return <About />
  }

  function RightBox() {
    if (uploadedDocument) {
      return <Description document={document} endpoint={endpoint} />
    }
    return <Upload />
  }

  return (
    <div className="App">
          <div className={'splitScreen'}>
            <div className={'pane leftAlign'}>
              <LeftBox />
            </div>
            <div className={'pane centerView'}>
              <RightBox />
            </div>
          </div>
    </div>
  );
}

export default Index;
