import React from 'react';
import '../App.css'

function About() {

  
  return (
    <div>
        <h1>Axtern</h1>
        <p>Your resume is a crucial part of your job search process, and it deserves a good home. At Axtern, we strive to offer you the ability to easily let employers retrieve the latest version of your resume and always be able to access it.</p>
        <p>We're powered by Cloudflare, allowing your documents to be globally distributed in less than a minute. We only store your documents and what you type in. Nothing else. No logins or unneeded cookies. We give you a password so you don't have to give us one.</p>
        <p>Document storage, but without the hassle.</p>
    </div>
  );
}

export default About;
