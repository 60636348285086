import React from 'react';

function View(props) {
    const { documentId, endpoint } = props

    return (
        <div>
            <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(`${endpoint}/document/${documentId}/view`)}&embedded=true`} className={'contentView'} />
        </div>
    );
}

export default View;
