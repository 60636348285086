import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css'

function Details() {
  const { document_id } = useParams();
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    fetch(`https://api.axtern.com/document/${document_id}/details`)
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setDocument(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, []);

  function View(props) {
    return (
        <div>
          <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(`https://api.axtern.com/document/${document_id}/view`)}&embedded=true`} className={'contentView'} />
        </div>
    );
  }

  function TitleField() {
    if (canEdit) {
      return (
        <div className='formItem'>
          <div className='formLabel'>
            <label htmlFor="title">Title</label><br/>
          </div>
          <div className='formInput'>
            <input id="title" type="text" defaultValue={document['title']} />
          </div>
        </div> 
      );
    }
    return null;
  }

  function ViewDescription(props) {
    const { document } = props

    return (
        <div>
            <h1>{document['title']}</h1>
            <TitleField />
            <div className='formItem'>
                <div className='formLabel'>
                    <label htmlFor="documentId">Document ID</label><br/>
                </div>
                <div className='formInput'>
                <input id="documentId" type="text" value={document_id} readOnly />
                </div>
            </div> 
        </div>
    );
  }

  function LeftBox() {
    if (isLoaded) {
      if (document === null) {
        return <div>
          <p>Error</p>
        </div>;
      }
      return <View documentId={document['documentId']} />
    }
  }

  function RightBox() {
      if (isLoaded) {
        if (document === null) {
          return <p>Failed to Locate Document</p>;
        } else {
          return <ViewDescription document={document} />
        }
      }
      return <p>Locating Document...</p>;
  }

  return (
    <div className="App">
          <div className={'splitScreen'}>
            <div className={'pane leftAlign'}>
              <LeftBox />
            </div>
            <div className={'pane centerView'}>
              <RightBox />
            </div>
          </div>
        </div>
  );
}

export default Details;
