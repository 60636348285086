import React, { useState } from 'react';
import '../App.css'

function Description(props) {
    const { document, endpoint } = props

    function copyUrl(text) {
        navigator.clipboard.writeText(`${endpoint}/d/${document['documentId']}`);
    }

    return (
        <div>
            <h1>Document Uploaded</h1>
            <div className='formItem'>
                <div className='formLabel'>
                    <label htmlFor="documentId">Document ID</label><br/>
                </div>
                <div className='formInput'>
                    <input id="documentId" type="text" value={document['documentId']} readOnly />
                </div>
            </div>
            <div className='formItem'>
                <div className='formLabel'>
                    <label htmlFor="publicUrl">Public URL</label><br/>
                </div>
                <div className='formInput'>
                    <input id="documentId" type="text" onClick={copyUrl} value={`https://axtern.com/d/${document['documentId']}`} readOnly />
                </div>
            </div>
            <div className='formItem'>
                <div className='formLabel'>
                    <label htmlFor="password">Password</label>
                </div>
                <div className='formInput'>
                    <input id="password" type="text" value={document['password']} readOnly />
                </div>
            </div>          
            <p>Your password allows you to update or erase your document in the future. Keep it somewhere safe.</p>  
        </div>
    );
}

export default Description;
